// import external dependencies
import 'jquery'; 

// Import everything from autoload
import './autoload/_bootstrap.js'
 
const swiper = new Swiper('.swiper', {
  speed: 400, 

  autoplay: {
    delay: 5000,
  },
  autoHeight: true, //enable auto height
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


const swiper2 = new Swiper('.swiper-news', {
  speed: 400, 
  spaceBetween: 30,
  slidesPerView: 4,
  autoplay: {
   delay: 5000,
 },
  autoHeight: true, //enable auto height
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    0: { /* when window >=0px - webflow mobile landscape/portriat */
      slidesPerView: 1,
      spaceBetween: 20,
    },
    767: { /* when window >= 767px - webflow tablet */
      slidesPerView: 2,
      spaceBetween: 30,
    },
    988: { /* when window >= 988px - webflow desktop */
      slidesPerView: 4,
      spaceBetween: 40,
    }
  },
});

$(window).scroll(function() {    
  var scroll = $(window).scrollTop();

   //>=, not <=
  if (scroll >= 30) { 
      $("header.banner").addClass("fixed");
  } else {
    $("header.banner").removeClass("fixed");
  }
}); //missing );



$(document).ready(function() {
  // Obsługa rozwijania/zwijania sub-menu po kliknięciu
  $('.menu-item-has-children > a').on('click', function(event) {
    const screenWidth = $(window).width();
    if (screenWidth < 991) {
      event.preventDefault();
      $(this).siblings('.sub-menu').toggleClass('d-block');
    }
  });

  // Obsługa zwijania sub-menu przy zmianie szerokości ekranu
  $(window).on('resize', function() {
    const screenWidth = $(window).width();
    if (screenWidth < 991) {
      $('.sub-menu').removeClass('d-block');
    }
  });
});


$(document).ready(function() {
  // Find the mobile menu button and the sidebar menu
  const sidebarToggle = $('#sidebar-toggle');
  const sidebarMenu = $('#sidebar-menu'); 
  if (sidebarToggle.length && sidebarMenu.length) {
      // Toggle the menu on button click
      sidebarToggle.on('click', function() {
          sidebarMenu.toggleClass('d-none');
      });
  }
});

$('.opener').on('click',  function(){
  if($(this).hasClass('open')) {
    $(this).removeClass('open')
  } else {
    $('.opener').removeClass('open')
    $(this).addClass('open')
  }
})